const PeriodToggle = (props: { periodSet: any; period: boolean }) => {
  return (
    <div className="period-toggle">
      <span
        onClick={() => {
          props.periodSet(false);
        }}
        className={!props.period ? "period-toggle-active" : ""}
      >
        DAN
      </span>
      /
      <span
        onClick={() => {
          props.periodSet(true);
        }}
        className={props.period ? "period-toggle-active" : ""}
      >
        OBDOBJE
      </span>
    </div>
  );
};

export default PeriodToggle;
