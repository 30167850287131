import React from "react";

const TextInput = (props: {
  setState: any;
  placeholder?: string;
  title?: string;
  subText?: string;
  oldVal?: string;
}) => {
  const handleChange = (e: any) => {
    props.setState(e.target.value);
  };
  return (
    <div className="input-container">
      {props.title && <h4>{props.title}</h4>}
      <input
        type="text"
        placeholder={props.placeholder}
        onKeyUp={handleChange}
        defaultValue={props.oldVal ? props.oldVal : ""}
      />
      {props.subText && <p>{props.subText}</p>}
    </div>
  );
};

export default TextInput;
