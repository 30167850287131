import { ReactElement } from "react";
import {
  CampaignLevelTotals,
  OptimzieResponseReactData,
} from "../../types/Optimize";
import RowCampaign from "./RowCampaign";
import Header from "./Header";
import OtherDataRow from "./OtherDataRow";
const TableBody = (props: { data: OptimzieResponseReactData }) => {
  const makeCampaigns = (data: CampaignLevelTotals[]) => {
    const result: ReactElement[] = [];
    data.map((camapign) => {
      result.push(<RowCampaign data={camapign} />);
    });
    return result;
  };

  return (
    <div className="table-div">
      <Header />
      <div className="table-data">
        {makeCampaigns(props.data.matchedOrders)}
      </div>
      <div className="table-data">
        {makeCampaigns(props.data.unmatchedOrders)}
      </div>
      <OtherDataRow data={props.data.googleAds} name={"Google Ads"} />
      <OtherDataRow
        data={props.data.undefinedOrders}
        name={"Undefined Orders"}
      />
    </div>
  );
};

export default TableBody;
