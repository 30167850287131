import React from "react";

const Header = () => {
  return (
    <thead>
      <tr className="totals-header-row ">
        <th className="totals-header-col store">Store</th>
        <th className="totals-header-col">Purchase</th>
        <th className="totals-header-col">Spend</th>
        <th className="totals-header-col">Value €</th>
        <th className="totals-header-col">Items</th>
        <th className="totals-header-col">ROAS</th>
        <th className="totals-header-col">CPP</th>
        <th className="totals-header-col">FB Value</th>
      </tr>
    </thead>
  );
};

export default Header;
