const LoadButton = (props: { trigger: any; disabled: boolean }) => {
  return (
    <button
      className="login-btn"
      onClick={props.trigger}
      disabled={props.disabled}
    >
      Load
    </button>
  );
};

export default LoadButton;
