import React, { ReactElement, useState } from "react";
import { AdLevelTotals, AdsetLevelTotals } from "../../types/Optimize";
import Column from "./Column";
import ColumnAdset from "./ColumnAdset";
import RowAd from "./RowAd";
const RowAdset = (props: { data: AdsetLevelTotals }) => {
  const [showAds, setShowAds] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const toggleAds = (e: any) => {
    setShowAds(!showAds);
    setOpen(!open);
  };

  let barva = "";
  let fontWeight = 400;

  if (props.data.ROAS > 3.99) {
    barva = "#5c9e07";
    fontWeight = 600;
  }
  if (props.data.ROAS < 2.2) {
    barva = "#bb3f3f";
    fontWeight = 600;
  }

  const makeAdRows = (ads: AdLevelTotals[]): ReactElement[] => {
    const result: ReactElement[] = [];
    props.data.ads.map((ad) => {
      const tmp: ReactElement = <RowAd data={ad} />;
      result.push(tmp);
    });

    return result;
  };

  return (
    <div className="table-row-adset-container">
      <div className="table-row-adset row" onClick={toggleAds}>
        <ColumnAdset
          data={props.data.adsetName}
          camapign={true}
          opened={open}
          barva={barva}
          fontWeight={fontWeight}
        />
        <Column data={props.data.totalOrderCount} />
        <Column data={props.data.totalSpend.toFixed(2)} />
        <Column data={props.data.totalOrderValue.toFixed(2)} />
        <Column
          data={props.data.ROAS ? props.data.ROAS.toFixed(2) : 0}
          barva={barva}
          fontWeight={fontWeight}
        />
        <Column data={props.data.CPP ? props.data.CPP.toFixed(2) : 0} />
      </div>
      <div className={`t-row-a-ad-container ${!showAds ? "skrito" : ""}`}>
        {makeAdRows(props.data.ads)}
      </div>
    </div>
  );
};

export default RowAdset;
