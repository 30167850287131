import React from "react";
import { queryDate } from "../../../types/Store";

const Month = (props: { state: queryDate; stateSet: any }) => {
  const fillSelect = (length: number) => {
    const dnevi = Array.from({ length: length }, (x, i) => i + 1);

    const options: any = [];
    dnevi.map((e) => {
      options.push(<option value={e}>{e}</option>);
    });
    return options;
  };

  const onChange = (e: any) => {
    const oldState = { ...props.state };
    oldState.month = e.target.value;
    props.stateSet(oldState);
  };
  return (
    <div className="select-container">
      <select
        onChange={onChange}
        defaultValue={new Date(Date.now()).getMonth() + 1}
      >
        {fillSelect(12)}
      </select>
    </div>
  );
};

export default Month;
