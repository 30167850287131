import React, { useEffect } from "react";
import CurrencySelectUpdate from "../components/currencies/CurrencySelectUpdate";
import CurrencyTable from "../components/currencies/CurrencyTable";
import CurrencySelect from "../components/stores/CurrencySelect";
import NumberInput from "../components/stores/NumberInput";
import TextInput from "../components/stores/TextInput";
import { proxy } from "../env/proxy";
import { Currency } from "../types/Currency";

const UpdateCurrencyPage = () => {
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [selectedCurrency, setSelectedCurrency] =
    React.useState<Currency | null>(null);
  const [currencyName, setCurrencyName] = React.useState<string | null>(null);
  const [currencyRate, setCurrencyRate] = React.useState<number | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reqError, setReqError] = React.useState<boolean>(false);
  const [reqSuccess, setReqSuccess] = React.useState<boolean>(false);
  const [missingData, setMissingData] = React.useState<boolean>(false);
  const fetchCurrencies = async () => {
    try {
      const response = await fetch(`${proxy.url}/currencies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${localStorage.getItem("AccessToken")}`,
        },
      });
      const processed = await response.json();
      setCurrencies(processed);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCurrencies();
  }, []);

  const updateCurrency = async () => {
    try {
      setReqSuccess(false);
      if (!selectedCurrency) {
        setMissingData(true);
        return;
      }
      if (!currencyRate) {
        setMissingData(true);
        return;
      }
      setMissingData(false);
      const oldCurrency = { ...selectedCurrency };
      if (currencyName) {
        oldCurrency.name = currencyName;
      }
      if (currencyRate) {
        oldCurrency.rate = currencyRate;
      }
      setLoading(true);
      const url = `${proxy.url}/currencies/${selectedCurrency?.id}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${localStorage.getItem("AccessToken")}`,
        },
        body: JSON.stringify({
          name: oldCurrency.name,
          rate: oldCurrency.rate,
        }),
      });
      const processed = await response.json();
      if (!processed || !processed.id) {
        setReqError(true);
        setLoading(false);
        return;
      }
      setReqSuccess(true);
      setLoading(false);
      fetchCurrencies();
    } catch (error) {
      console.log(error);

      setReqError(true);
      setLoading(false);
    }
  };

  return (
    <div className="content currency-page">
      <h1>Posodobi Valute</h1>
      <div className="inline">
        <CurrencyTable data={currencies} />
        {missingData ? <h4>Vnesi vse podatke!</h4> : ""}
        {reqSuccess ? <h4>Valuta je posodobljena!</h4> : ""}
        {reqError ? <h4>Prislo je do napake.</h4> : ""}
        {loading ? <h4>Ustvarjanje valute...</h4> : ""}

        <div className="input-super-conatiner">
          <h4>Izberi valuto za posodobitev</h4>
          <CurrencySelectUpdate
            data={currencies}
            setCurrency={setSelectedCurrency}
          />
          <div className="inline">
            <TextInput
              title="Ime Valute"
              setState={setCurrencyName}
              placeholder={"EUR"}
              subText={"Na primer: EUR"}
              oldVal={selectedCurrency?.name}
            />
            <NumberInput
              setState={setCurrencyRate}
              placeholder={"1.0"}
              title={"Tecaj"}
              oldVal={selectedCurrency?.rate}
            />
          </div>

          <button onClick={updateCurrency} className={"login-btn"}>
            Posodobi
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateCurrencyPage;
