import React from "react";

const ColumnAdset = (props: {
  data: string | number | undefined;
  camapign?: boolean;
  barva?: string;
  opened: boolean;
  fontWeight?: number;
}) => {
  const openedStyle = { transform: "rotate(90deg)" };
  return (
    <div
      className={`table-value-col col-adset ${
        props.camapign ? "campaign" : ""
      }`}
    >
      <img
        src="/angle-right-solid.svg"
        style={props.opened ? openedStyle : {}}
      ></img>
      {props.data}
    </div>
  );
};

export default ColumnAdset;
