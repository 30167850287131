import React from "react";
import { NoAdDataTotals } from "../../types/Optimize";
import Column from "./Column";

const OtherDataRow = (props: { data: NoAdDataTotals; name: string }) => {
  return (
    <div className="table-footer-row row">
      <Column data={props.name} campaign={true} />
      <Column data={props.data.totalOrdersCount} />
      <Column data={0} />
      <Column data={props.data.totalOrderValue.toFixed(2)} />
      <Column data={0} />
      <Column data={0} />
    </div>
  );
};

export default OtherDataRow;
