import React from "react";
import { Currency } from "../../types/Currency";

const CurrencyRow = (props: { data: Currency }) => {
  return (
    <tr>
      <td>{props.data.name}</td>
      <td>{props.data.rate}</td>
    </tr>
  );
};

export default CurrencyRow;
