import { ReactElement } from "react";
import { SSTotals, TotalsResponseObject } from "../../types/Totals";
import Header from "./Header";
import Row from "./Row";
const Table = (props: { data: TotalsResponseObject }) => {
  const displayTotals = (data: SSTotals[]): ReactElement[] => {
    const result: ReactElement[] = [];
    data.map((store) => {
      const tmp: SSTotals = { ...store };
      result.push(<Row data={tmp} />);
    });
    return result;
  };
  return (
    <table className="totals-table">
      <Header />
      <tbody>
        {displayTotals(props.data.totals)}
        <Row data={props.data.cumulativeTotals} />
      </tbody>
    </table>
  );
};

export default Table;
